<template>
    <template v-if="!is_loading">
        <div style="overflow: auto; height: calc(100vh - 60px - 91px - 58px);" v-if="!chatId" @touchstart.stop
            @touchmove.stop @touchend.stop>
            <div class="px-10 pt-10 px-sm-20 chat-item cursor-pointer position-relative"
                v-for="(chat, chat_id) in chats" :key="chat_id">
                <div @click="open_chat(chat_id)" class="chat-block__left_item d-flex gap-10"
                    :class="{ 'active': chat.is_active }">
                    <span v-if="chat.new_mes" class="new-mess-counter badge bg-primary rounded-circle">
                        <span v-text="chat.new_mes >= 99 ? '99+' : chat.new_mes"></span>
                    </span>
                    <div class="chat-block__left_item-icon">
                        <img style="height:50px; width: 50px; border-radius: 5px" v-if="chat.offers[0].mainPhoto"
                            :src="chat.offers[0].mainPhoto.miniUrl" alt="img">
                    </div>

                    <div class="chat-block__left_item-text d-flex flex-column justify-content-between">
                        <span style="max-width: calc(100vw - 85px); overflow: hidden; text-overflow: ellipsis;"
                            class="name ">{{ chat.users[0].name }}</span>
                        <span style="font-size: 12px; line-height: 14.4px;" class="letter text-body-tertiary">{{
                            chat.offers[0].title }}</span>
                        <span style="font-size: 8px; line-height: 9.6px;" class="when text-body-tertiary">{{
                            chat.chats[0].update_at
                            }}</span>

                    </div>
                </div>
            </div>
        </div>

        <template v-else>
            <div class="messages-header">
                <img class="cursor-pointer" @click="closeChat" src="@/assets/icons/backwards.svg">
                <img style="height:40px; width: 40px; border-radius: 5px" v-if="imageGroup" :src="imageGroup" alt="img">
                <div class="chat-block__top_right-text d-flex flex-column justify-content-between">
                    <span class="text-ellipsis name-chat-cian"
                        style="font-size: 14px; line-height: 16.8px; color: #222222;">{{
                            nameGroup }}</span>
                    <span class="quantity-participant-cian text-ellipsis">
                        <a href="javascript:{}" @click="openObjectCard" class="message__block_name">{{ nameChat }}</a>
                    </span>
                </div>
                <div class="chat-block__top_right-icons">
                    <form class="chat-block__top_right-form" style="display: none;">
                        <input placeholder="Поиск" type="text" class="search">
                        <button>
                            <img src="/images/chat/search-icon.svg" alt="img">
                        </button>
                    </form>
                    <button class="dots chat-block__top_right-btn dots-modal-show" style="display: none;">
                        <img src="/images/chat/menu-icon.svg" alt="img">
                    </button>
                </div>
            </div>
            <div class="chat-block__top_right px-10 px-sm-20 pt-20 pb-10 position-relative d-flex gap-20 flex-column">
                <div class="messages-container" ref="messagesRef" @touchstart.stop @touchmove.stop @touchend.stop
                    :style="{ height: `calc(100dvh - ${containerHeightToCalc}px)` }">
                    <div class="day-container d-flex flex-column justify-content-center align-items-center gap-20"
                        v-for="(_mes, key) in listMessage" :key="key">
                        <div v-html="key" style="font-size: 14px; line-height: 16.8px; color: #666666" />
                        <div class="message">
                            <!-- getMessageUserAvatar -->
                            <div :id="`message-${mes.messageId}`" class="message__info"
                                :style="mes.direction == 'out' ? 'flex-direction: row-reverse; margin-left: 40px;' : 'margin-right: 40px;'"
                                v-for="(mes) in _mes" :key="mes.id">
                                <!-- <img :src="getMessageUserAvatar(mes)" alt="Аватарка"> -->
                                <div class="message__block">
                                    <a class="message__block_name">{{ getMessageUserName(mes) }}</a>
                                    <span class="message__block_text"
                                        style="max-width: calc(100vw - 40px); text-wrap: wrap;">{{
                                            mes.content.text }}</span>
                                    <span class="message__block_time">{{ this.formatDate('HH:mm', mes.createdAt)
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="messages-footer">
                    <router-link to='/add-requisition'
                        class="d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
                        style="width:40px;height:40px;">
                        <img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить">
                    </router-link>
                    <TextArea class="messages-footer__mes-input" inputId="new-message" inputName="message"
                        :inputRequired="true" :inputValue="text_mes" inputClass="rounded-1 mb-10"
                        inputWrapClass="flex-column mb-3" @inputTyped="(e) => handleChangeInput(e)" />
                    <div class="messages-footer__send-message" @click="send_mes_cian">
                        <img :src="require('@/assets/icons/direct-message.svg')" alt="Отправить">
                    </div>
                </div>
            </div>
        </template>
    </template>
    <div v-else class="d-flex flex-column align-items-center">
        <Loader />
    </div>
</template>

<script>
import api from "@/api";
import CommonService from "@/services/CommonService";
import TextArea from "@/components/inputs/TextArea";
import Loader from "@/components/common/Loader";
import moment from 'moment';
import { nextTick } from 'vue';

export default {
    components: {
        TextArea,
        Loader
    },
    data() {
        return {
            textareaHeight: '40px',
            user_id: null,
            agency_id: null,
            chats: {},
            chatsIds: [],
            nameGroup: '',
            nameChat: '',
            imageGroup: null,
            listMessage: {},
            chatId: null,
            error: false,
            error_mes: '',
            text_mes: '',
            can_chat: false,
            user_global: [],
            total_new: 0,
            is_loading: true,
            initial_counter: 0
        }
    },
    methods: {
        closeChat() {
            this.$emit('setActiveChat', null)
            this.chatId = null
            this.listMessage = {}
        },
        handleChangeInput(e) {
            if (!e.target.value) {
                this.textareaHeight = `40px`
            } else if (e.target.scrollHeight > 40) {
                this.textareaHeight = `${e.target.scrollHeight}px`
            }

            this.text_mes = e.target.value
        },
        formatDate(format, datetime) {
            const date = moment(datetime);

            if (date.isValid()) {
                return CommonService.formatDateTime(date, 'ru', format);
            }

            return datetime
        },

        get_user_global() {
            return api.get('/chats/get-user', {
                params: {
                    user_id: this.user_id
                }
            }).then((response) => {
                this.user_global = response.data;
                this.get_can_chat();

            }).catch(function (error) {
                console.log(error);
            });
        },

        get_messages() {
            return api.post('/chats/get-cian-messages', {
                agency_id: this.agency_id,
                user_id: this.user_id,
                user: this.user_global,
            }).then((response) => {
                this.chats = {};
                const res = response.data

                let unread_chat_messages = 0;
                if (res.chats) {
                    for (let i = 0; i < res.chats.length; i++) {
                        this.chatsIds = Array.from(new Set([...this.chatsIds, res.chats[i].id]));

                        unread_chat_messages++
                        if (this.chats['chat' + res.chats[i].chatid]) {

                            if (res.details[res.chats[i].id] && res.details[res.chats[i].id].chats[0].messages) {

                                for (let j = 0; j < res.details[res.chats[i].id].chats[0].messages.length; j++) {
                                    if (!this.isEmpty(res.details[res.chats[i].id].chats[0].messages[j])) {

                                        if (res.details[res.chats[i].id].chats[0].messages[j].direction == 'in') {
                                            this.chats['chat' + res.chats[i].chatid].users = res.details[res.chats[i].id].users;
                                        }

                                        if (res.details[res.chats[i].id].chats[0].messages[j].new == 1) {
                                            this.chats['chat' + res.chats[i].chatid].new_mes = this.chats['chat' + res.chats[i].chatid].new_mes || 0;
                                            this.chats['chat' + res.chats[i].chatid].new_mes++;
                                        }

                                        const chatKey = 'chat' + res.chats[i].chatid;
                                        const messages = this.chats[chatKey].chats[0].messages;


                                        messages.push(res.details[res.chats[i].id].chats[0].messages[j]);

                                        this.chats[chatKey].chats[0].messages = this.chats[chatKey].chats[0].messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
                                    }
                                }
                            }

                        } else {
                            res.details[res.chats[i].id].is_active = false;
                            res.details[res.chats[i].id].new_mes = 0;
                            if (res.details[res.chats[i].id].chats[0].messages[0].new == 1) {
                                res.details[res.chats[i].id].new_mes++;
                            }
                            const chatKey = 'chat' + res.chats[i].chatid;
                            this.chats[chatKey] = res.details[res.chats[i].id];
                        }
                    }

                    this.$store.commit('set_chat_ids', { chat_type: 'cian_chat_ids', chatIds: this.chatsIds })
                }

                this.is_loading = false;

                return response
            })
                .catch(function (error) {
                    console.log(error);
                })
        },

        get_can_chat() {
            api.get('/chats/can-user-chat', {
                params: {
                    user_id: this.user_id,
                    service_id: 2,
                }
            }).then((response) => {
                if (response.data.can === true) {
                    this.get_messages().finally(() => {
                        this.is_loading = false
                    });

                }
            }).catch(function (error) {
                console.log(error);
            });
        },


        open_chat(chatId) {
            this.error = false,
                this.error_mes = '';
            this.nameGroup = '';
            this.nameChat = '';
            if (this.chats[chatId].users[0].name) {
                if (!isNaN(this.chats[chatId].users[0].name)) {
                    this.nameGroup = "Пользователь (ID " + this.chats[chatId].users[0].name + ")";
                } else {
                    this.nameGroup = this.chats[chatId].users[0].name;
                }
            }
            this.imageGroup = null;
            this.imageGroup = this.chats[chatId].offers[0].mainPhoto.miniUrl


            if (this.chats[chatId].offers[0].title) {
                this.nameChat = this.chats[chatId].offers[0].title
            }

            for (let i in this.chats) {
                if (i !== chatId) {
                    this.chats[i].is_active = false;
                }
            }

            this.$store.commit('set_unread_messages_count', {
                cian: this.$store.getters.unread_messages_count.cian - this.chats[chatId].new_mes
            })

            this.get_message_chat(chatId)
            this.$emit('setActiveChat', chatId);

            this.chatId = `chat${chatId}`;
            this.chatId = typeof chatId == 'number' ? `chat${chatId}` : chatId;
        },

        prepareChatMessages(messages) {
            this.listMessage = {}
            Object.values(messages)
                .forEach((message) => {
                    let date = moment(message.createdAt, 'DD.MM.YYYY HH:mm')

                    if (!date.isValid()) {
                        date = moment(message.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSSSSSZZ')
                    }

                    message.createdAt = date


                    let formatedDate;
                    if (date.isValid()) {

                        formatedDate = this.formatDate('DD.MM.YYYY', date);

                        const dateToCheck = date;
                        const isToday = dateToCheck.isSame(moment(), 'day');
                        const isYesterday = dateToCheck.isSame(moment().subtract(1, 'days'), 'day');

                        if (isToday) {
                            formatedDate = "Сегодня"
                        } else if (isYesterday) {
                            formatedDate = "Вчера"
                        } else {
                            formatedDate = moment(date).format('DD MMMM YYYY [г.]')
                        }

                    } else {
                        formatedDate = "Сегодня";
                    }
                    if (!this.listMessage[formatedDate]) {
                        this.listMessage[formatedDate] = []
                    }

                    this.listMessage[formatedDate].push(message)

                    return message
                });
        },


        get_message_chat(chatId) {
            this.error = false,
                this.error_mes = '';

            return api.post('/chats/get-message-chat-cian', {
                chatId: chatId,
            }).then((response) => {
                const res = response.data;

                this.chats[chatId].chats[0].messages = {};
                this.chats[chatId].new_mes = 0;
                var k = 0;
                const messages = {}
                for (let i = 0; i < res.length; i++) {
                    this.chatId = typeof res[i].chat_id == 'number' ? `chat${res[i].chat_id}` : res[i].chat_id;

                    this.chats[chatId].chats[0].messages = {};
                    for (let j = 0; j < res[i].chats[0].messages.length; j++) {
                        if (!this.isEmpty(res[i].chats[0].messages[j])) {

                            if (!Object.values(messages).find((mess) => mess.messageId == res[i].chats[0].messages[j].messageId)) {
                                this.chats[chatId].chats[0].messages[j] = res[i].chats[0].messages[j];
                                messages[k] = res[i].chats[0].messages[j]

                                k++;
                            }
                        }
                    }
                }

                this.prepareChatMessages(messages)

            }).catch(function (error) {
                console.log(error);
            });
        },

        send_mes_cian() {
            if (!this.isEmpty(this.chatId)) {
                let chatId = this.chatId;
                let chat_id_cian = chatId.match(/\d+/g).join('');

                if (!this.isEmpty(this.text_mes)) {
                    let text = this.text_mes;
                    this.text_mes = '';

                    api.post('/chats/send-message', {
                        chat_id: chat_id_cian,
                        text: text,
                        service_id: 2,
                        agency_id: this.agency_id,
                    }).then(() => {
                        setTimeout(() => {
                            this.get_message_chat(chatId);
                        }, 500)
                    }).catch(function (error) {
                        console.log(error);
                    });
                }
            } else {

                this.error = true;
                this.error_mes = "Для отправки сообщения необходимо выбрать чат";
            }
        },

        // getMessageUserAvatar(message) {
        //     if (message.direction == 'out') {
        //         return this.userInfo.avatar
        //     }

        //     if (!this.chatId) return null;

        //     const userInfo = this.chats[this.chatId].users.find((user) => user.userId == message.userId);

        //     if (userInfo) {
        //         return userInfo.avatar.images.small
        //     }

        //     return null;
        // },

        getMessageUserName(message) {
            if (message.userName) return message.userName

            return this.chats[this.chatId].users.find((user) => user.userId == message.userId)?.name
        },

        openObjectCard() {
            // if (this.chats[this.chatId].object_id > 0) {
            // openObjectModal(this.chats[this.chatId].object_id);
            // $('.chat-cian').css('zIndex','unset');
            // }
        },

        //Проверка на пустоту
        isEmpty(data) {
            if (typeof (data) === 'object') {
                if (JSON.stringify(data) === '{}' || JSON.stringify(data) === '[]') {
                    return true;
                } else if (!data) {
                    return true;
                }
                return false;
            } else if (typeof (data) === 'string') {
                if (!data.trim()) {
                    return true;
                }
                return false;
            } else if (typeof (data) === 'undefined') {
                return true;
            } else {
                return false;
            }
        },
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo
        },
        containerHeightToCalc() {
            const siteHeaderHeight = document.querySelector('.header')?.clientHeight || 0;

            const messagesHeaderHeight = document.querySelector('.messages-header')?.clientHeight || 71

            const textAreaHeight = parseInt(this.textareaHeight.match(/\d+/)[0], 10);

            return siteHeaderHeight + messagesHeaderHeight + textAreaHeight + 60
        },
        need_refetch() {
            return this.$store.getters.need_refetch_messages.cian
        }
    },
    mounted() {
        this.user_id = this.userInfo.id
        this.agency_id = this.userInfo.agency_id
        this.get_user_global();


        nextTick(() => {
            if (this.need_refetch) {
                this.$store.commit('set_need_refetch_messages', { section: 'cian', value: false })
            }
        })

    },
    watch: {
        'need_refetch': function (val) {
            if (val) {
                if (this.chatId) {
                    this.get_message_chat(this.chatId)
                } else {
                    this.get_messages()
                }
            }

            this.$store.commit('set_need_refetch_messages', { section: 'cian', value: false })
        }
    },
    updated() {
        if (this.chatId && Object.keys(this.listMessage).length) {
            const groupValues = Object.values(this.listMessage)

            const lastGroupMessages = groupValues[groupValues.length - 1]

            const lastMessage = lastGroupMessages[lastGroupMessages.length - 1]

            if (lastMessage) {
                const lastMessageContainer = document.getElementById(`message-${lastMessage.messageId}`);
                const bodyContainer = document.getElementsByTagName('body')[0];

                if (lastMessageContainer) {
                    lastMessageContainer.scrollIntoView({ behavior: 'smooth', block: 'end' })
                    bodyContainer.scrollIntoView({ behavior: 'smooth', block: 'end' })
                }
            }

        }
    }
}
</script>

<style lang="scss">
.footer {
    display: none !important;
}
.text-ellipsis {
    max-width: calc(-120px + 100vw);
    overflow: hidden;
    text-overflow: ellipsis;
}

.messages-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    padding-bottom: 20px;
}

.message {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    &__info {
        display: flex;
        gap: 10px;

        img {
            margin-top: 10px;
            width: 32px;
            height: 32px;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 10px;
        gap: 10px;
        border-radius: 5px;
        background: #FFFFFF;
        width: 100%;
    }

    &__block_name {
        line-height: 16.8px;
        color: #029EFF;
        font-size: 14px;
    }

    &__block_time {
        font-size: 14px;
        line-height: 16.8px;
        color: #888888
    }
}

.messages-footer {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    left: 0;
    gap: 10px;
    padding: 10px;
    background-color: #FFFFFF;
    transition: .10s all;

    &__mes-input {
        margin-bottom: 0 !important;
        display: flex;
        flex-grow: 1;

        textarea {
            height: v-bind(textareaHeight);
            width: 100%;
            min-height: 40px;
            overflow: hidden;
            padding: 10px;
            font-size: 14px;
            margin: 0 !important;
        }
    }

    &__send-message {
        height: 40px;
        width: 40px;
        border-radius: 12px;
        border: 1px solid #DEDEDE;
        background-color: #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

.messages-header {
    display: flex;
    gap: 10px;
    background-color: #FFFFFF;
    padding: 20px 10px 10px 10px;
    border-bottom: 1px solid #e8d8d8;
}

.chat-item:last-child {
    padding-bottom: 10px;
}

.with-space {
    bottom: 58px !important;
}

.new-mess-counter {
    position: absolute;
    width: 17px;
    font-size: 8px;
    line-height: 9px;
    height: 17px;
    top: 17px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>